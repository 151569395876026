import { api } from ":mods";
import { ENDPOINTS } from "../../const";

export async function postQuestion(id:string|number,data) {
      return api
        .postAuth(ENDPOINTS.material_details_with_material_id_student + id, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (res) => {
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });

    }